import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../redux/user/actions'
import { Store } from '../../redux/store'
import getBearerToken from '../../utils/getBearerToken'
import { METRICS_PATH, PROFILE_PATH } from '../../consts'
import { HISTORY_IS_LOADED } from '../../redux/user/actionTypes'
import { setLastReadTimetoken } from '../../redux/lastReadMessage/actions'
import { useHistory } from 'react-router-dom'

const appURL = window.location.protocol + '//' + window.location.host

interface AuthProps {
  children
}

const Auth = (props: AuthProps) => {
  var history = useHistory()
  const { children } = props
  localStorage.setItem('nonEmptyAccount', '0')
  const dispatch = useDispatch()
  if (!localStorage.getItem('latest-timetoken'))
    dispatch(setLastReadTimetoken(String(new Date().getTime() * 10000)))
  const user_logged_in = !!useSelector((store: Store) => store.user.logged_in)
  const should_redirect = useSelector((store: Store) => store.error.should_redirect)

  const pageClosed = () => {
    localStorage.setItem('onReloadForMetrics', '0')
  }
  window.addEventListener('beforeunload', pageClosed)

  useEffect(() => {
    const cookie_value = getBearerToken()
    const params = new URLSearchParams(window.location.search)
    const query = params.get('bearer')
    const access_token = query || cookie_value
    if (access_token) {
      document.cookie = `tfxbearer=${access_token} ; path=/`
      dispatch({ type: HISTORY_IS_LOADED })

      if (localStorage.getItem('pollingAccounts') != undefined) {
        var pollingAccounts = JSON.parse(localStorage.getItem('pollingAccounts'))
        pollingAccounts.map((account) => {
          dispatch(
            getUser(
              true,
              account?.id,
              account?.account_number,
              account?.status,
              account?.process_stage
            )
          )
        })
      } else {
        dispatch(getUser())
      }

      if (query) {
        if (parseInt(localStorage.getItem('accountPolling'))) {
          window.history.pushState({}, '', PROFILE_PATH)
        } else {
          history.push(METRICS_PATH)
        }
      }
    } else {
      if (
        window.location.pathname !== '/' &&
        window.location.pathname !== '/login/reset_password' &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/signup' &&
        window.location.pathname !== '/invite_registration' &&
        window.location.pathname !== '/privacypolicy' &&
        window.location.pathname !== '/cookies' &&
        window.location.pathname !== '/terms' &&
        window.location.pathname !== '/terms_and_conditions' &&
        window.location.pathname !== '/broker'
      ) {
        window.location.href = appURL
      }
    }
  }, [])

  useEffect(() => {
    if (should_redirect) {
      let url = appURL

      url += '?expired=true'
      window.location.href = url
    }
  }, [should_redirect])
  if (user_logged_in) {
    return children
  }
  // loader here
  return null
}

export default Auth
