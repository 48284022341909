// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/soleil-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/soleil-regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/soleil-regular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/soleil-bold.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/soleil-bold.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../assets/soleil-bold.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../assets/soleil-book.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../../assets/soleil-book.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../../assets/soleil-book.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
exports.push([module.id, "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{padding:0;border:0;margin:0;font:inherit;font-size:100%;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:\"\";content:none}table{border-collapse:collapse;border-spacing:0}input,a{outline:none}*{box-sizing:border-box}@font-face{font-family:\"soleil-regular\";font-style:normal;font-weight:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}@font-face{font-family:\"soleil-bold\";font-style:normal;font-weight:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}@font-face{font-family:\"soleil-book\";font-style:normal;font-weight:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ")}._14wuAiN1ylFycHcm830Vfd{display:flex;width:50px;height:50px;align-items:center;justify-content:center;border-radius:50%;color:#fff;font-family:\"soleil-regular\";box-shadow:inset 0px 4px 4px rgba(0,0,0,.35)}._3hjYA4Z1SugAPg5kttsVMJ{background:linear-gradient(#7367f0 0%, #7367f0 100%)}.o7CCZ2RyM1b2heViQmZFk:hover{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"alphaGreen": "rgba(2,198,134,.28)",
	"alphaRed": "rgba(255,73,73,.28)",
	"aqua": "#00afd0",
	"aquaLight": "#c8f6ff",
	"brightYellow": "#ffff09",
	"communityColor": "#272eda",
	"darkGrey": "#1a1723",
	"darkGrey2": "#1f2024",
	"darkerGrey": "#14101e",
	"darkerGreyAlpha": "rgba(20,16,30,.85)",
	"green": "#02c686",
	"greenDark": "#105e44",
	"greenMain": "#00b377",
	"lightGrey": "gray",
	"lightGrey2": "#454545",
	"lightGrey4": "#404040",
	"offBlack": "#0c0c0e",
	"purple": "#5922fe",
	"redMain": "#ff4949",
	"white": "#fff",
	"avatar": "_14wuAiN1ylFycHcm830Vfd",
	"avatarGradient": "_3hjYA4Z1SugAPg5kttsVMJ",
	"avatarHover": "o7CCZ2RyM1b2heViQmZFk"
};
module.exports = exports;
