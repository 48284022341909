import produce, { Draft, enableES5 } from 'immer'
import { SET_CURRENCY_PAIR, SET_DATE_FROM, SET_DATE_TO, SET_TRADER_FILTER, SET_DATE_PERIOD, SET_PER_PAGE, SET_SORT_OPTIONS } from './actionTypes'
import { InitialState, AuthAction } from './traderTableFilterTypes'
import moment from 'moment'

enableES5()

export const initial_state: InitialState = {
  assets: [],
  date_from: null,
  date_to: null,
  date_period: "all",
  trader_filter:[],
  per_page:10,
  sort_options:{
    orderBy: 'gain',
    order: 'desc',
    orderTitle: 'Gain',
  }
}

export default produce((draft: Draft<InitialState>, action: AuthAction) => {
  switch (action.type) {
    case SET_CURRENCY_PAIR:
      draft.assets = action.payload ? action.payload : []
      break

      case SET_TRADER_FILTER:
        draft.trader_filter = action.payload ? action.payload : []
        break

        case SET_SORT_OPTIONS:
        draft.sort_options = action.payload ? action.payload : {
          orderBy: 'gain',
          order: 'desc',
          orderTitle: 'Gain',
        }
        break

        case SET_DATE_PERIOD:
        draft.date_period = action.payload ? action.payload : null
        break

        case SET_PER_PAGE:
        draft.per_page = action.payload ? action.payload : null
        break

    case SET_DATE_FROM:
      draft.date_from = action.payload
        ? moment(action.payload).format('YYYY-MM-DD')
        : null
      break

    case SET_DATE_TO:
      draft.date_to = action.payload ? moment(action.payload).format('YYYY-MM-DD') : null
      break

    default:
      break
  }
}, initial_state)
