// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2octxVSpyL_wdb2iC3ncyZ{padding:\"2em\";color:\"white\"}._3ggDQsnHr01kphY9Dxux6f{padding-top:\"1.8em\";padding-bottom:\"1.8em\";font-size:\"1.2rem\"}._3QjQWLx78-PSWXy7z-fC7A{width:100%;padding:20px 0 40px 0;background:#14101e}._1DwSZOZZnG1JZ8NNEUeEiS{display:flex;align-items:center;flex-direction:column}._1DwSZOZZnG1JZ8NNEUeEiS img{margin-right:20px}@media screen and (max-width: 500px){._3QjQWLx78-PSWXy7z-fC7A{padding-bottom:20px}}._1g5DPgBpWHOFg8jFEnlccg{display:flex;height:100px;flex-direction:column;align-self:center;justify-content:space-between}@media screen and (min-width: 600px){._1g5DPgBpWHOFg8jFEnlccg{height:auto;flex-direction:row}}._3oLQryOgkSkGrKkOmtwigq{position:absolute;top:0;right:0;width:1em;height:1em;margin-left:auto;cursor:pointer;z-index:1}._3K3irJlpkuPiXk2FTgxn_2{display:flex;align-self:center}._3K3irJlpkuPiXk2FTgxn_2 span{color:gray !important}._3K3irJlpkuPiXk2FTgxn_2 a{margin-left:20px;color:gray !important;cursor:pointer;font-family:\"soleil-regular\";font-size:12px;text-decoration:underline}._3K3irJlpkuPiXk2FTgxn_2 a:visited{color:gray}._67h9qdG5viXgFRm2szr2W{height:calc(100% - 2.4em);padding:1.2em;color:#fff;overflow-y:scroll}._67h9qdG5viXgFRm2szr2W h3{margin-top:.75em;font-family:\"soleil-bold\"}._67h9qdG5viXgFRm2szr2W p{margin-top:.75em;font-family:\"soleil-regular\";font-size:14px}._67h9qdG5viXgFRm2szr2W ul{padding-left:.75em;font-family:\"soleil-regular\";font-size:14px;list-style-type:disc}._67h9qdG5viXgFRm2szr2W ul li{margin-top:.5em}", ""]);
// Exports
exports.locals = {
	"alphaGreen": "rgba(2,198,134,.28)",
	"alphaRed": "rgba(255,73,73,.28)",
	"aqua": "#00afd0",
	"aquaLight": "#c8f6ff",
	"brightYellow": "#ffff09",
	"communityColor": "#272eda",
	"darkGrey": "#1a1723",
	"darkGrey2": "#1f2024",
	"darkerGrey": "#14101e",
	"darkerGreyAlpha": "rgba(20,16,30,.85)",
	"green": "#02c686",
	"greenDark": "#105e44",
	"greenMain": "#00b377",
	"lightGrey": "gray",
	"lightGrey2": "#454545",
	"lightGrey4": "#404040",
	"offBlack": "#0c0c0e",
	"purple": "#5922fe",
	"redMain": "#ff4949",
	"white": "#fff",
	"modal": "_2octxVSpyL_wdb2iC3ncyZ",
	"modalHeader": "_3ggDQsnHr01kphY9Dxux6f",
	"footer": "_3QjQWLx78-PSWXy7z-fC7A",
	"footerLogo": "_1DwSZOZZnG1JZ8NNEUeEiS",
	"footerContent": "_1g5DPgBpWHOFg8jFEnlccg",
	"footerClose": "_3oLQryOgkSkGrKkOmtwigq",
	"footerLinks": "_3K3irJlpkuPiXk2FTgxn_2",
	"modalText": "_67h9qdG5viXgFRm2szr2W"
};
module.exports = exports;
