import axios from 'axios'
import store from '../redux/store'
import { redirectToLanding } from '../redux/globalActions'
import { Api } from './apiTypes'
import getBearerToken from '../utils/getBearerToken'
import getEnvironment from '../utils/getEnvironment'
import { AccessLevel } from '../common'

const baseURL = getEnvironment()

export default (): Api => {
  const request = axios.create({
    baseURL,
  })
  let REQUEST_AUTHENTICATED = false

  const createRequest = () => {
    if (REQUEST_AUTHENTICATED) return request

    const auth_token = getBearerToken()

    request.defaults.headers.common['Authorization'] = auth_token
    request.defaults.headers['content-type'] = 'application/json'
    request.interceptors.response.use(successfulResponse, errorResponse)
    REQUEST_AUTHENTICATED = true

    return request
  }

  const successfulResponse = (response) => response

  const errorResponse = (error) => {
    if (error.response && error.response.data.code === 401) {
      store.dispatch(redirectToLanding())
    }
    return Promise.reject(error)
  }

  const authConfig = async () => {
    const request = createRequest()
    const url = '/auth'

    return request.get(url)
  }

  const charts = async ({ url }) => {
    // let url

    const request = createRequest()
    return request.get(url)
  }

  const feed = async ({ url }) => {
    // let url

    const request = createRequest()
    return request.get(url)
  }

  // const adminTraders = async ({
  //   accno,
  //   type,
  //   period,
  //   count,
  //   group,
  //   agg,
  //   date_to,
  //   date_from,
  //   url,
  // }) => {
  //   //let url
  //   const options = {url,json:{
  //     conditions: [
  //       ["account_number", "EQ", "123456"]
  //     ]
  //   }}
  //   const request = createRequest()
  //   return request.get(url,{data:{
  //     "conditions": [['gain', '>=', 0]]
  //   }})

  // }

  const adminTraders = async ({ url }) => {
    const request = createRequest()
    return request.get(url)
  }

  const userAndAccount = async () => {
    const url = '/user'
    const request = createRequest()

    return Promise.all([request.get(url), request.get(`${url}/account`)]).then(
      (values) => {
        return values
      }
    )
  }

  const traderAccount = async ({ account_id }) => {
    const url = `/trader/${account_id}`
    const request = createRequest()
    return request.get(url)
  }

  const pollAccount = async ({ account_id }) => {
    const url = `/mt4/poll/${account_id}`
    const request = createRequest()
    return request.get(url)
  }
  const traderStats = async ({
    accno,
    access_level,
    date_from,
    date_to,
    date_period,
  }) => {
    let url = `/trader/${accno}/stats`
    const request = createRequest()

    if (date_from && date_to) {
      url += `?date_from=${date_from.format('YYYY-MM-DD')}&date_to=${date_to.format(
        'YYYY-MM-DD'
      )}`
    } else if (date_period) {
      url += `?period=${date_period}`
    }
    if (access_level >= 50 && localStorage.getItem('selected_staff_user')) {
      url += `&user_id=${localStorage.getItem('selected_staff_user')}`
    }

    return request.get(url)
  }

  // user/account returns array of accounts
  // trader/accno/stats returns stats obj with account_number

  const trades = async ({
    accno,
    type,
    page,
    per_page = 10,
    period = 'monthly',
    date_from,
    date_to,
    assets = [],
    isDepositWithdraw,
    order,
    orderBy,
    access_level = null,
  }) => {
    let url = `/trader/${accno}/trade/status/${type}`
    if (page) {
      if (!isDepositWithdraw) {
        url += `?page=${page}&per_page=${per_page}`
      } else {
        url += `?page=${page}&per_page=${100}`
      }
    }
    const request = createRequest()
    const has_asset = assets !== null && assets.some((asset) => asset.length > 0)
    if (isDepositWithdraw) {
      url += `${page ? '&' : '?'}order_type=6`
    } else {
      if (has_asset) {
        url += `${page ? '&' : '?'}asset=${assets.map((asset, i) =>
          i > assets.length - 1 ? `${asset},` : asset
        )}`
      }
      if (date_from && date_to) {
        url += `${page || has_asset ? '&' : '?'}date_from=${date_from}&date_to=${date_to}`
      } else if (period) {
        url += `${page || has_asset ? '&' : '?'}period=${period}`
      }
    }
    if (order && orderBy) {
      url += `&order=${orderBy}:${order}`
    }
    if (access_level >= 50 && localStorage.getItem('selected_staff_user')) {
      url += `&user_id=${localStorage.getItem('selected_staff_user')}`
    }
    return request.get(url)
  }

  const topAssetPairs = async ({
    acc_id,
    period,
    date_from,
    date_to,
    count,
    group,
    access_level,
  }) => {
    let url

    const request = createRequest()

    if (date_from && date_to) {
      url = `/trader/${acc_id}/chart?date_from=${date_from.format(
        'YYYY-MM-DD'
      )}&date_to=${date_to.format('YYYY-MM-DD')}&group=${group}`
    } else {
      url = `/trader/${acc_id}/chart?period=${period}&group=${group}&count=${count}`
    }
    if (access_level >= 50 && localStorage.getItem('selected_staff_user')) {
      url += `&user_id=${localStorage.getItem('selected_staff_user')}`
    }

    return request.get(url)
  }

  const getAllUsers = async () => {
    const url = '/admin/user'
    const request = createRequest()

    return request.get(url)
  }

  const getAccessLevels = async () => {
    const url = '/admin/role'
    const request = createRequest()

    return request.get(url)
  }

  const setAccessLevel = async ({ user_id, access_level }) => {
    const url = `/admin/user/${user_id}/access_level`
    const request = createRequest()

    return request.post(url, {
      access_level,
    })
  }

  const getAssignedUsers = async (page_number, per_page_count, id, order) => {
    const number_of_pages = page_number || 1
    const per_page = 10
    let url = ''
    if (id || order)
      url = `/admin/assigned/user?page=${number_of_pages}&per_page=${per_page}&order=${
        id + ':' + order
      }`
    else url = `/admin/assigned/user?page=${number_of_pages}&per_page=${per_page}`
    const request = createRequest()

    return request.get(url)
  }

  const getAdminStats = async ({ date_from, date_to }) => {
    let url = '/admin/platform/stats'
    if (date_from || date_to) url += `?date_from=${date_from}&date_to=${date_to}`
    //  url = `/admin/platform/stats`
    const request = createRequest()

    return request.get(url)
  }

  const getUnassignedUsers = async (page_number, per_page_count, id, order) => {
    const number_of_pages = page_number
    const per_page = 20
    let url = ''
    if (id || order)
      url = `/admin/unassigned/user?page=${number_of_pages}&per_page=${per_page}&order=${
        id + ':' + order
      }`
    else url = `/admin/unassigned/user?page=${number_of_pages}&per_page=${per_page}`
    const request = createRequest()

    return request.get(url)
  }

  const unassignUser = async ({ user_ids, prev_mentor_id }) => {
    return new Promise((resolve, reject) => {
      const url = 'user/assign'

      const xhr = new XMLHttpRequest()
      xhr.open('DELETE', baseURL + url, true)
      xhr.setRequestHeader('Content-type', 'application/json; chartset=utf-8')
      xhr.setRequestHeader('Authorization', getBearerToken())
      xhr.onload = () => {
        if (xhr.readyState == 4 && xhr.status == '200') {
          return resolve(true)
        } else {
          return reject(new Error('Error unassigning user.'))
        }
      }
      xhr.send(
        JSON.stringify({
          user_ids,
          mentor_id: prev_mentor_id,
        })
      )
    })
  }

  const assignUser = async ({ user_ids, mentor_id }) => {
    const url = '/user/assign'
    const request = createRequest()
    return request.post(url, {
      user_ids,
      mentor_id,
    })
  }

  const deleteMentor = async ({ user_ids, mentor_id }) => {
    const url = '/user/assign'
    const request = createRequest()
    return request.delete(url, {
      data: {
        user_ids,
        mentor_id,
      },
    })
  }

  const reassignUser = async ({ user_ids, mentor_id, prev_mentor_id }) => {
    const unassigned = await unassignUser({ user_ids, prev_mentor_id })
    const assigned = await assignUser({ user_ids, mentor_id })

    return { ...unassigned, ...assigned }
  }

  const getStaff = async () => {
    const url = '/admin/staff?per_page=50'
    const request = createRequest()
    return request.get(url)
  }

  const getStages = async () => {
    const url = '/admin/stage'
    const request = createRequest()

    return request.get(url)
  }

  const registerMT4 = async ({
    account_number,
    user_id,
    broker_server_id,
    broker_id,
    password,
    mt_version,
    allow_investor_sharing,
  }) => {
    const url = '/mt4/register'
    const request = createRequest()

    return request.post(url, {
      account_number,
      user_id,
      broker_id,
      broker_server_id,
      password,
      source: 'afx',
      orphan: false,
      mt_version,
      allow_investor_sharing,
    })
  }

  //need to call account endppount
  // getAllAccountData

  const getBrokers = async (mt_version, account_type) => {
    const url = `mt4/broker?mt_version=${mt_version}&demo=${account_type}`
    const request = createRequest()

    return request.get(url)
  }

  const getBrokerServers = async (company, mt_version, account_type) => {
    const url = `/mt4/broker/${company}/server?mt_version=${mt_version}&demo=${account_type}`
    const request = createRequest()

    return request.get(url)
  }

  const getMentorDetails = async () => {
    const url = '/user/mentor'
    const request = createRequest()

    return request.get(url)
  }

  const removeAccount = async (account_id: number, user_id: number) => {
    return new Promise((resolve, reject) => {
      const url = '/mt4/assign'

      const xhr = new XMLHttpRequest()
      xhr.open('DELETE', baseURL + url, true)
      xhr.setRequestHeader('Content-type', 'application/json; chartset=utf-8')
      xhr.setRequestHeader('Authorization', getBearerToken())
      xhr.onload = () => {
        if (xhr.readyState == 4 && xhr.status == 200) {
          return resolve(true)
        } else {
          return reject(Error('Error deleting account.'))
        }
      }
      xhr.send(
        JSON.stringify({
          account_id,
          user_id,
        })
      )
    })
  }

  const addNote = async (account_id, ticket, note) => {
    const url = `/trader/${account_id}/trade/${ticket}/note`
    const request = createRequest()
    return request.post(url, {
      note,
    })
  }
  const getNotes = async (account_id, ticket) => {
    const url = `/trader/${account_id}/trade/${ticket}/note`
    const request = createRequest()
    return request.get(url)
  }

  const deleteNotes = async (account_id, ticket, note_id) => {
    const url = `/trader/${account_id}/trade/${ticket}/note/${note_id}`
    const request = createRequest()
    return request.delete(url)
  }

  const getCountries = async () => {
    const url = '/admin/country'
    const request = createRequest()

    return request.get(url)
  }

  const getCities = async (country_code: string) => {
    const url = `/admin/country/${country_code}/city`
    const request = createRequest()

    return request.get(url)
  }

  const saveUserMobile = async ({ mobile_number, city, country }) => {
    const url = '/user/mobile'
    const request = createRequest()

    return request.post(url, {
      country,
      city,
      mobile_number,
    })
  }

  const confirmMobile = async (token: string) => {
    const url = '/user/mobile/verify'
    const request = createRequest()

    try {
      return request.post(url, { token }).catch((err) => {
        return err
      })
    } catch (err) {
      return err
    }
  }

  const deleteUser = async (user_id: number) => {
    return new Promise((resolve, reject) => {
      const url = 'user'

      const xhr = new XMLHttpRequest()
      xhr.open('DELETE', baseURL + url, true)
      xhr.setRequestHeader('Content-type', 'application/json; chartset=utf-8')
      xhr.setRequestHeader('Authorization', getBearerToken())
      xhr.onload = () => {
        if (xhr.readyState == 4 && xhr.status == '200') {
          return resolve(true)
        } else {
          return reject(new Error('Error deleting user.'))
        }
      }
      xhr.send(
        JSON.stringify({
          user_id,
        })
      )
    })
  }

  const getCountryData = async () => {
    const url = '/report/country/user'
    const request = createRequest()
    return request.get(url)
  }

  const getUsersByCountry = async (country_code: string) => {
    const url = `/admin/country/${country_code}/user`
    const request = createRequest()
    return request.get(url)
  }

  const inviteNewStaff = async (email: string) => {
    const url = '/admin/invite'
    const request = createRequest()
    return request.post(url, { email, access_level: AccessLevel.staff })
  }

  const authorize = async ({
    provider = 'google',
    type = null,
    access_token = null,
    invite_uuid = null,
  }) => {
    let url = '/auth'
    const request = createRequest()

    if (!provider && !type) {
      request.get(url).then(() => {}) //console.log(res)
    }
    if (provider && type && access_token) {
      url += `/${provider}/${type}`

      if (invite_uuid) {
        return request.post(url, { access_token, invite_uuid })
      }
      return request.post(url, { access_token })
    } else {
      throw Error('Invalid provider/auth token')
    }
  }

  const forgottenPassword = async (email) => {
    const request = createRequest()
    const url = '/auth/password/forgotten'

    if (email) {
      return request.post(url, { email })
    }
  }

  const resetPassword = async ({ password, passwordConfirm, token }) => {
    const request = createRequest()
    const url = '/auth/password/reset'

    if (token && password) {
      return request.post(url, {
        token: token,
        password: password,
        confirm_password: passwordConfirm,
      })
    }
  }

  const basicLogin = async ({ email, password }) => {
    const request = createRequest()
    const url = '/auth/basic/login'

    if (email && password) {
      return request.post(url, {
        email,
        password,
      })
    }
  }

  const basicRegister = async ({
    invite_uuid,
    email,
    password,
    first_name,
    last_name,
    avatar,
  }) => {
    const request = createRequest()
    const url = '/auth/basic/register'

    if (email && password && first_name && last_name) {
      return request.post(url, {
        email,
        password,
        first_name,
        last_name,
        invite_uuid,
        avatar,
      })
    }
  }

  const submitContactForm = async (first_name, last_name, email, message) => {
    const url = '/admin/enquiry'
    const request = createRequest()
    return request.post(url, {
      first_name: first_name,
      last_name: last_name,
      email_address: email,
      message: message,
    })
  }

  // API_HOSTANAME/admin/99/sale?status=confirmed

  const getConfirmedSalesSalesUser = async (user_id, period, date_from, date_to) => {
    let url = `/admin/${user_id}/sale?status=confirmed`
    if (date_to !== null && date_from !== null) {
      url = `/admin/${user_id}/sale?status=confirmed&date_from=${date_from}&date_to=${date_to}`
    } else if (period) {
      url = `/admin/${user_id}/sale?status=confirmed&date_from=${period}`
    }
    const request = createRequest()
    return request.get(url)
  }

  const getSaleList = async (period, date_from, date_to) => {
    let url = '/admin/sale'
    if (date_to !== null && date_from !== null) {
      url = `/admin/sale?date_from=${date_from}&date_to=${date_to}`
    } else if (period) {
      url = `/admin/sale?date_from=${period}`
    }
    const request = createRequest()
    return request.get(url)
  }

  const unconfirmedSaleToConfirmed = async ({ sale_id, commission_perc, status }) => {
    const url = `admin/sale/${sale_id}`
    const request = createRequest()
    return request.post(url, {
      commission_perc,
      confirmed: status,
    })
  }

  const addSale = async ({
    user_id,
    mentor_user_id,
    credit_type,
    cost_gbp,
    credit,
    credit_ccy,
    commission_perc,
    date,
  }) => {
    const request = createRequest()
    const url = '/admin/sale'

    return request.post(url, {
      user_id,
      mentor_user_id,
      credit_type,
      cost_gbp,
      credit,
      credit_ccy,
      commission_perc,
      date,
    })
  }

  const deleteSale = async ({ sale_id }) => {
    const url = `admin/sale/${sale_id}`
    const request = createRequest()
    return request.delete(url)
  }

  const updateUserStage = async (userStageResponse) => {
    const url = `/admin/user/${userStageResponse.user_id}/stage`
    const request = createRequest()
    return request.post(url, {
      stage: userStageResponse.stage,
    })
  }

  const getServer = async (company) => {
    const url = `mt4/broker/${company}/server    `
    const request = createRequest()
    return request.get(url)
  }

  const getConnectedAccounts = async (user_id) => {
    const url = `user/account?user_id=${user_id}`
    const request = createRequest()
    return request.get(url)
  }

  const getAccountNotes = async (user_id) => {
    const url = `trader/${user_id}/note`
    const request = createRequest()
    return request.get(url)
  }
  const setAccountNotes = async (user_id, note) => {
    const url = `trader/${user_id}/note`
    const request = createRequest()
    return request.post(url, note)
  }

  const tradeSearch = async (term, col, sortType, page, stage, staffId) => {
    let url = ''
    if (col || sortType) {
      if (staffId === '')
        url = `trader/search?term=${term}&page=${page}&per_page=${10}&order=${
          col + ':' + sortType
        }&stage=${stage}`
      else
        url = `trader/search?term=${term}&page=${page}&per_page=${10}&order=${
          col + ':' + sortType
        }&stage=${stage}&staff_user_id=${staffId}`
    } else {
      if (staffId === '')
        url = `trader/search?term=${term}&page=${page}&per_page=${10}&stage=${stage}`
      else
        url = `trader/search?term=${term}&page=${page}&per_page=${10}&stage=${stage}&staff_user_id=${staffId}`
    }
    const request = createRequest()
    return request.get(url)
  }

  const paymentConfig = async () => {
    const url = 'subscription/payment/config'
    const request = createRequest()
    return request.get(url)
  }

  const subscriptionPlans = async () => {
    const url = 'subscription'
    const request = createRequest()
    return request.get(url)
  }

  const authorisePayment = async ({ payment_intent }) => {
    const url = 'subscription/authorise'
    const request = createRequest()
    return request.post(url, { payment_intent })
  }

  const initiatePayment = async ({ subscription_id, payment_method }) => {
    const url = 'subscription/initiate'
    const request = createRequest()
    return request.post(url, { subscription_id, payment_method })
  }

  const attachPaymentMethod = async ({ payment_method }) => {
    const url = 'subscription/payment/method'
    const request = createRequest()
    return request.post(url, payment_method)
  }

  const finalisePayment = async ({ payment_intent }) => {
    const url = 'subscription/finalise'
    const request = createRequest()
    return request.post(url, { payment_intent })
  }

  const cancelSubscription = async () => {
    const url = 'subscription/cancel'
    const request = createRequest()
    return request.delete(url)
  }

  const reactivateSubscription = async () => {
    const url = 'subscription/reactivate'
    const request = createRequest()
    return request.post(url)
  }

  const changeSubscription = async ({ subscription_id }) => {
    const url = 'subscription/alter'
    const request = createRequest()
    return request.post(url, { subscription_id })
  }

  const subscriptionPayments = async () => {
    const url = 'subscription/payment'
    const request = createRequest()
    return request.get(url)
  }

  const subscriptionPaymentMethods = async () => {
    const url = 'subscription/payment/method'
    const request = createRequest()
    return request.get(url)
  }

  const useDefaultCard = async (id) => {
    const url = 'subscription/payment/method'
    const request = createRequest()
    return request.put(url, { payment_method: id })
  }

  const deleteSavedCard = async (id) => {
    const url = 'subscription/payment/method'
    const request = createRequest()
    return request.delete(url, {
      data: {
        payment_method: id,
      },
    })
  }

  const addNewCard = async (id) => {
    const url = 'subscription/payment/method'
    const request = createRequest()
    return request.post(url, { type: 'card', token: id })
  }

  const saveSocialUser = async (IGname, TWname) => {
    const url = '/user/social/update'
    const request = createRequest()
    if (IGname !== '' && TWname !== '') {
      return request.post(url, {
        add: [
          {
            kind: 'instagram',
            value: IGname,
          },
          {
            kind: 'twitter',
            value: TWname,
          },
        ],
        remove: [''],
      })
    } else if (IGname !== '' && TWname === '') {
      return request.post(url, {
        add: [
          {
            kind: 'instagram',
            value: IGname,
          },
        ],
        remove: ['twitter'],
      })
    } else if (IGname === '' && TWname !== '') {
      return request.post(url, {
        add: [
          {
            kind: 'twitter',
            value: TWname,
          },
        ],
        remove: ['instagram'],
      })
    } else if (IGname === '' && TWname === '') {
      return request.post(url, {
        add: [],
        remove: ['instagram', 'twitter'],
      })
    }
  }

  const callmeNow = async () => {
    const url = '/trader/callback'
    const request = createRequest()
    return request.post(url)
  }

  const getTradeSentiment = async () => {
    const url = '/trade/sentiment'
    const request = createRequest()
    return request.get(url)
  }

  const getNews = async () => {
    const url = '/news/get'
    const request = createRequest()
    return request.get(url)
  }

  return {
    setAccountNotes,
    getAccountNotes,
    trades,
    charts,
    feed,
    adminTraders,
    userAndAccount,
    traderAccount,
    pollAccount,
    traderStats,
    getAllUsers,
    getAssignedUsers,
    getAccessLevels,
    setAccessLevel,
    getStages,
    topAssetPairs,
    registerMT4,
    removeAccount,
    addNote,
    getNotes,
    getCountries,
    getCities,
    saveUserMobile,
    confirmMobile,
    deleteUser,
    reassignUser,
    assignUser,
    deleteMentor,
    getCountryData,
    getUsersByCountry,
    getUnassignedUsers,
    getStaff,
    inviteNewStaff,
    getBrokers,
    getBrokerServers,
    getMentorDetails,
    authConfig,
    authorize,
    forgottenPassword,
    resetPassword,
    basicLogin,
    basicRegister,
    submitContactForm,
    deleteNotes,
    deleteSale,
    addSale,
    unconfirmedSaleToConfirmed,
    getSaleList,
    updateUserStage,
    getConfirmedSalesSalesUser,
    getServer,
    getConnectedAccounts,
    getAdminStats,
    tradeSearch,
    paymentConfig,
    initiatePayment,
    finalisePayment,
    subscriptionPlans,
    cancelSubscription,
    reactivateSubscription,
    changeSubscription,
    subscriptionPayments,
    attachPaymentMethod,
    subscriptionPaymentMethods,
    useDefaultCard,
    deleteSavedCard,
    addNewCard,
    authorisePayment,
    saveSocialUser,
    callmeNow,
    getTradeSentiment,
    getNews,
  }
}
