import React, { useEffect, useState, useRef } from 'react'
import styles from './contact.module.scss'

import Text from '../../text'
import Button from '../../button'
import Input from '../../input'
import api from '../../../api'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'
import { Store } from '../../../redux/store'
const API = api()
import CloseIcon from '../../../assets/closeIcon'
interface IContactForm {
  open?: boolean
  showLogin?: () => void
  hideModal?: () => void
  validationRegex: string
  validationDescription: string
}

interface SpacerI {
  children: JSX.Element
}

const Spacer = ({ children }: SpacerI) => {
  return <div style={{ marginTop: '15px', width: '100%' }}>{children}</div>
}

const ContactForm = ({
  hideModal
}: IContactForm): JSX.Element | null => {
  // 1 uppercase 1 number
  const firstRender = useRef(true)
  const [message, setMessage] = useState('')

  //user info

  const user = useSelector((store: Store) => store.user.logged_in)
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

  }, [])
  const disableRequest = () => {
    if (message) {
      return false
    }
    return true
  }

  const submitContactForm = async () => {
    try {
      await API.submitContactForm(user.first_name, user.last_name, user.email, message)
      swal('Congrats!', 'Your Enquiry has been submitted!', 'success')
      hideModal()
    } catch (error) {
      hideModal()
      swal(
        'Form was not submitted successfully',
        'AN ERROR WAS OCCURED, Please try agian later.',
        'error'
      )
    }
  }
  return (
    <div className={`${styles.contact} `}>
      <div
        style={{
          color: 'white',
          top: '20px',
          right: '20px',
          position: 'absolute',
          width: '20px',
          height: '20px',
          cursor: 'pointer',
        }}
        onClick={hideModal}
      >
        <CloseIcon />
      </div>
      <div className={styles.contactTitle}>
        <Text value="Contact TrackFX" size="head3" color="White" bold />
      </div>

      <Spacer>
        <div className={styles.contactForm}>
          {/* <Input
            type="text"
            placeholder="First Name"
            onChange={setFirstName}
            value={user.first_name}
          />
          <Input
            type="text"
            placeholder="Last Name"
            onChange={setLastName}
            value={user.last_name}
          /> */}

          <Input
            type="textarea"
            placeholder="Message"
            onChange={setMessage}
            value={message}
          />
          {/* <Captcha onSubmitCapatcha={onSubmitCapatcha} /> */}
        </div>
      </Spacer>
      <Button
        onClick={() => {
          submitContactForm()
        }}
        gradient
        size="Sm"
        className={styles.contactCta}
        disabled={disableRequest()}
      >
        Send
      </Button>
    </div>
  )
}

export default ContactForm
