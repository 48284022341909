import { useState } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import Text from '../text'
import Modal from '../../components/modal'
import ContactForm from '../../components/landing/contact'
// #TODO: REMOVE IN PRODUCTION
import { version } from '../../../package.json'
import styles from './footer.module.scss'
import { motion, AnimatePresence } from 'framer-motion'
import LogoGreyIcon from '../../assets/LogoGrey'

const Footer = () => {
  const [show_contact_form, setShowContactForm] = useState(false)
  const validation_regex = null
  const validation_description = null

  const base_url = window.location.origin

  return (
    <div className={styles.footer}>
    
       
      <Modal
        open={show_contact_form}
        onClose={() => {
          setShowContactForm(false)
        }}
      >
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{
              ease: 'easeInOut',
              velocity: 150,
              default: { duration: 0.25 },
            }}
            style={{ height: '100%' }}
          >
            <ContactForm
              hideModal={() => {
                setShowContactForm(false)
              }}
              validationDescription={validation_description}
              validationRegex={validation_regex}
            />
          </motion.div>
        </AnimatePresence>
      </Modal>{' '}
      <Container>
        <Row>
          <Col sm={12} className={styles.footerContent}>
            <div className={styles.footerLogo}>
              {/* <img src={Logo} /> */}
              <LogoGreyIcon/>
              </div>
            <div className={styles.footerLinks}>
              <Text value={`v${version}`} size="body1" color="Green"/>
              {/* <a onClick={() => setShowContactForm(true)}>Contact options</a> */}
              <a onClick={() => window.open(`${base_url}/terms`, '_blank')}> Terms & Conditions</a>         
              <a onClick={() => window.open(`${base_url}/privacypolicy`, '_blank')}> Privacy Policy</a>       
              <a onClick={() => window.open(`${base_url}/cookies`, '_blank')}> Cookies</a>    
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
