import produce, { Draft, enableES5 } from 'immer'
import {
  STORE_ADMIN_TRADERS,
  SET_LOADING_STATUS_TRUE,
  SET_LOADING_STATUS_FALSE
} from './actionTypes'
import { InitialState, AuthAction } from './superAdminTypes'

enableES5()

export const initial_state: InitialState = {
  traders: [],
  loading_status: true
}

export default produce((draft: Draft<InitialState>, action: AuthAction) => {
  switch (action.type) {
    
    case STORE_ADMIN_TRADERS:
      draft.traders = action.payload
      break
      case SET_LOADING_STATUS_FALSE:
          draft.loading_status = false
           break
           case SET_LOADING_STATUS_TRUE:
            draft.loading_status = true
             break
  }
}, initial_state)
