// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/soleil-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/soleil-regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/soleil-regular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./assets/soleil-bold.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./assets/soleil-bold.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./assets/soleil-bold.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./assets/soleil-book.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./assets/soleil-book.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./assets/soleil-book.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
exports.push([module.id, "@font-face{font-family:\"soleil-regular\";font-style:normal;font-weight:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}@font-face{font-family:\"soleil-bold\";font-style:normal;font-weight:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}@font-face{font-family:\"soleil-book\";font-style:normal;font-weight:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ")}body{width:100%;font-family:\"soleil-book\" !important}._2efWgJD4ufbTJmuL2rDp26{overflow-y:scroll;width:100%;height:100%;position:fixed;overflow-y:auto}._3-TKiPtlER7s-e0juoBRFA{margin-top:-1px;flex-grow:1;padding-bottom:60px;background-color:#14101e}@media screen and (max-width: 600px){._3-TKiPtlER7s-e0juoBRFA{padding-bottom:0px}}", ""]);
// Exports
exports.locals = {
	"alphaGreen": "rgba(2,198,134,.28)",
	"alphaRed": "rgba(255,73,73,.28)",
	"aqua": "#00afd0",
	"aquaLight": "#c8f6ff",
	"brightYellow": "#ffff09",
	"communityColor": "#272eda",
	"darkGrey": "#1a1723",
	"darkGrey2": "#1f2024",
	"darkerGrey": "#14101e",
	"darkerGreyAlpha": "rgba(20,16,30,.85)",
	"green": "#02c686",
	"greenDark": "#105e44",
	"greenMain": "#00b377",
	"lightGrey": "gray",
	"lightGrey2": "#454545",
	"lightGrey4": "#404040",
	"offBlack": "#0c0c0e",
	"purple": "#5922fe",
	"redMain": "#ff4949",
	"white": "#fff",
	"landingPage": "_2efWgJD4ufbTJmuL2rDp26",
	"mainPage": "_3-TKiPtlER7s-e0juoBRFA"
};
module.exports = exports;
