import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Store } from '../../redux/store'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'
import styles from './header.module.scss'
import { noAccountConnected, noAccountConnectedBanner } from '../../redux/globalActions'
import { setLastReadTimetoken } from '../../redux/lastReadMessage/actions'
import Link from '../link'
import Avatar from '../avatar'
import Text from '../text'

import * as CONSTS from '../../consts'

import { AccessLevel } from '../../common'

import '../../utils/reactGridSystemConfig'
import usePubnub from '../../hooks/usePubnub'
// import useResizeListener from '../../hooks/useResizeListener'
import LogoIcon from '../../assets/Logo'
const Header = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const selected = useSelector((store: Store) => store.user.account_number)
  const setSidebarOpen = false
  const pathname = location.pathname
  const account_polling = useSelector((store: Store) => store.user.polling_in_progress)
  const pollAccount = account_polling.filter((ac) => ac.account === selected)
  if (pollAccount) {
    var currentPollAccount = pollAccount[0]?.account
  }
  const user = useSelector((store: Store) => store.user.logged_in)
  const accounts = useSelector((store: Store) => store.user.logged_in.accounts)
  const account_number = accounts[0] ? accounts[0].account_number : null
  const no_account_number = !account_number
  const can_access_admin =
    useSelector((store: Store) => store.user.logged_in.access_level) >= AccessLevel.staff
  const accounts_change_message = useSelector((store: Store) => store.addAccount.message)
  // const { show_no_account_banner, banner_message: no_account_message } = useSelector(
  //   (store: Store) => store.addAccount
  // )
  const [profile, setProfile] = useState<boolean>()
  const account_failed_message = 'Failed to add account, please retry.'
  const name = `${user.first_name} ${user.last_name || ''}`
  // const [should_display_sidebar, setShouldDisplaySidebar] = useState<boolean>(false)

  const pubnub = usePubnub()

  const messageCountTimer = useRef(null)

  const user_id = useSelector((store: Store) => store.user.logged_in.id)
  const chat_channel_id = `channel-${user_id}`

  // const resizeListener = () => setShouldDisplaySidebar(window.innerWidth <= 767)

  const { access_level, stage } = useSelector((store: Store) => store.user.logged_in)
  const selected_account =
    accounts.find((acc) => acc.account_number === account_number) || null
  const updated_data = useSelector((store: Store) => store.metrics)
  const shouldShowNavigation = () => {
    if (stage >= 2 || access_level >= 50) {
      return true
    } else {
      return false
    }
  }

  const shouldShowCommunity = () => {
    if (stage >= 3 || access_level >= 50) {
      return true
    } else {
      return false
    }
  }

  const handleLogoClick = () => {
    history.push(CONSTS.METRICS_PATH)
  }

  // if (access_level > AccessLevel.user && access_level < AccessLevel.superuser) {
  //   window.location.href = '/admin/sales'
  // }

  // useResizeListener(resizeListener)

  useEffect(() => {
    if (window.location.pathname == '/members/community') {
      dispatch(setLastReadTimetoken(String(new Date().getTime() * 10000)))
    }
  }, [window.location.pathname, selected_account, updated_data])

  useEffect(() => {
    if (no_account_number && window.location.pathname === CONSTS.METRICS_PATH)
      goToProfile()
  }, [no_account_number, window.location.pathname])

  useEffect(() => {
    pubnub.subscribe({ channels: [chat_channel_id], withPresence: true })
    pubnub.setState(
      {
        state: {
          mood: 'grumpy',
        },
        channels: [chat_channel_id],
      },
      () => {
        // handle state setting response
      }
    )
    pubnub.addListener({
      presence: () => {
        pubnub.setState(
          {
            state: {
              mood: 'grumpy',
            },
            channels: [chat_channel_id],
          },
          () => {
            // handle state setting response
          }
        )
      },
    })

    return () => {
      clearInterval(messageCountTimer.current)
    }
  }, [])
  useEffect(() => {}, [access_level])
  useEffect(() => {
    shouldShowNavigation()
    shouldShowCommunity()
    if (
      stage === 1 &&
      account_polling[0] === undefined &&
      accounts_change_message !== account_failed_message
    ) {
      dispatch(noAccountConnectedBanner())
      dispatch(noAccountConnected())
      return
    }
  }, [stage, user, account_polling])

  const goToProfile = () => {
    setProfile(true)
    history.push({
      pathname: CONSTS.PROFILE_PATH,
      state: { detail: profile },
    })
  }

  return (
    <div
      className={styles.headerWrapper}
      style={
        no_account_number
          ? { backgroundColor: '#1f1d2c' }
          : { backgroundColor: '#14101e' }
      }
    >
      {/* {show_no_account_banner && (
        <div className={styles.noAccountMessage}>{no_account_message}</div>
      )} */}
      <Container style={{ zIndex: 1 }}>
        {/* {should_display_sidebar && stage && (
          <Row>
            <Col>
              <HamburgerMenu
                onClick={() => setSidebarOpen(true)}
                isOpen={sidebar_is_open}
              > */}
        {/* {shouldShowNavigation() && (
                  <nav className={styles.hamburgerNav}>
                    <div
                      style={{
                        color: 'white',
                        top: '20px',
                        right: '20px',
                        position: 'absolute',
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() => setSidebarOpen(false)}
                    >
                      <CloseIcon />
                    </div>
                    <Link
                      label="Metrics"
                      path={CONSTS.METRICS_PATH}
                      active={pathname === CONSTS.METRICS_PATH}
                      sidebar
                      onClick={() => setSidebarOpen(false)}
                      disabled={no_account_number}
                    />
                    <Link
                      label="Trades"
                      path={CONSTS.TRADES_PATH}
                      active={pathname === CONSTS.TRADES_PATH}
                      sidebar
                      onClick={() => setSidebarOpen(false)}
                      disabled={no_account_number}
                    />
                    <Link
                      label="Deposits & Withdrawals"
                      path={CONSTS.DEPOSIT_WITHDRAW_PATH}
                      active={pathname === CONSTS.DEPOSIT_WITHDRAW_PATH}
                      sidebar
                      onClick={() => setSidebarOpen(false)}
                      disabled={no_account_number}
                    />
                    {shouldShowCommunity() && (
                      <Link
                        label="Inbox"
                        path={CONSTS.COMMUNITY_PATH}
                        active={pathname === CONSTS.COMMUNITY_PATH}
                        sidebar
                        notificationCount={unread_message_count}
                        onClick={() => setSidebarOpen(false)}
                      />
                    )}
                    {can_access_admin && (
                      <Link
                        label="Admin"
                        path={CONSTS.ADMIN_DASHBOARD_PATH}
                        active={pathname === CONSTS.ADMIN_DASHBOARD_PATH}
                        sidebar
                        onClick={() => setSidebarOpen(false)}
                      />
                    )}
                  </nav>
                )}
              </HamburgerMenu>
            </Col>
            
          </Row>
        )} */}
        <></>

        <Row>
          <Col>
            <div className={styles.header}>
              <div className={styles.logo} onClick={handleLogoClick}>
                <LogoIcon />
              </div>
            </div>
          </Col>

          <Visible xs>
            <Col xs="content">
              <div className={styles.header}>
                <div className={styles.loggedInUser}>
                  <Avatar fullname={name} onClick={goToProfile} />

                  {/* <div className={styles.loggedInUserDetails}>
                  <Text size="head3" color="White" value={name} bold />
                  <Text
                    size="body1"
                    color="Grey"
                    value="My Account"
                    className={styles.loggedInUserDetailsLink}
                    onClick={goToProfile}
                    active={pathname === CONSTS.PROFILE_PATH}
                  />
                </div> */}
                </div>
              </div>
            </Col>
          </Visible>
          <Hidden xs>
            <Col sm="content">
              <div className={styles.header}>
                <div className={styles.loggedInUser}>
                  <Avatar fullname={name} />

                  <div className={styles.loggedInUserDetails}>
                    <Text size="head3" color="White" value={name} bold unchanged />
                    <Text
                      size="body1"
                      color="Grey"
                      value="My Account"
                      unchanged
                      className={styles.loggedInUserDetailsLink}
                      onClick={goToProfile}
                      active={pathname === CONSTS.PROFILE_PATH}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Hidden>
        </Row>
        <Row>
          <Col className={styles.menuDiv}>
            {shouldShowNavigation() && (
              <div className={styles.menu}>
                <nav>
                  <Link
                    label="Metrics"
                    path={CONSTS.METRICS_PATH}
                    active={pathname === CONSTS.METRICS_PATH}
                    disabled={no_account_number || currentPollAccount == selected}
                  />
                  <Link
                    label="Trades"
                    path={CONSTS.TRADES_PATH}
                    active={pathname === CONSTS.TRADES_PATH}
                    disabled={no_account_number || currentPollAccount == selected}
                  />
                  <Link
                    label="Deposits & Withdrawals"
                    path={CONSTS.DEPOSIT_WITHDRAW_PATH}
                    active={pathname === CONSTS.DEPOSIT_WITHDRAW_PATH}
                    disabled={no_account_number || currentPollAccount == selected}
                  />
                  <Link
                    label="Track"
                    path={CONSTS.TRACK_PATH}
                    active={pathname === CONSTS.TRACK_PATH}
                    disabled={no_account_number || currentPollAccount == selected}
                  />
                  <Link
                    label="Brokers"
                    path={CONSTS.BROKER_PATH}
                    active={pathname === CONSTS.BROKER_PATH}
                    disabled={no_account_number || currentPollAccount == selected}
                  />
                  {can_access_admin && (
                    <Link
                      label="Admin"
                      path={CONSTS.ADMIN_MEMBERS_PATH}
                      active={pathname === CONSTS.ADMIN_DASHBOARD_PATH}
                      onClick={() => setSidebarOpen(false)}
                    />
                  )}
                  {/* {can_access_admin && (
                    <Link
                      label="Feed"
                      path={CONSTS.ADMIN_FEED_PATH}
                      active={pathname === CONSTS.FEED_PATH}
                      //onClick={() => setSidebarOpen(false)}
                    />
                  
                 
                  )} */}
                </nav>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Header
